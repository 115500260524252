import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
                    
                         {/* 1st block */}
                            <div className="col-span-12 lg:col-span-4">
                                {/* <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                                    <h3 className="font-bold text-4xl mb-4">Acklod LLC</h3>
                                    <div className='text-md font-medium text-gray-600'>
                                        <h5>210 Professional Center</h5>
                                        <p>Westminster,</p>
                                        <p>Maryland,</p>
                                        <p>USA</p>
                                    </div>
                                </div> */}
                            </div>

                              {/* 2nd block */}
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box-border border-b-4 border-blue-900 p-8 bg-white-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                                <h4 className="font-bold text-4xl mb-4">Acklod LLC</h4>
                                <div className='text-md font-medium text-gray-600'>
                                <h5>Email: info@acklod.com</h5>
                                <h5>Phone: (410) 929-6211</h5>
                                    {/* <h5>123 Professional Center, Suite#101</h5>
                                    <p>Westminster,</p>
                                    <p>Maryland,</p>
                                    <p>USA</p>
                                    <p>info@acklod.com</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-black-200  py-1">
                            Copyright &copy; {new Date().getFullYear()}{"  "} Acklod LLC. All rights reserved.
                            </div>
                        </div>
                </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
