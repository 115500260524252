import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import PageNotFound from '../components/PageNotFound';

const NotFound = () => {
    return (
        <>
           <div>
                <NavBar />
            </div>
            <PageNotFound />
            <Footer/>
        </>

    )
}

export default NotFound;

