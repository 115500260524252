import React from 'react';
import img from '../images/Database.svg'; 
import aichatbot from '../images/AI-Chatbot.svg';
import img3 from '../images/Security.svg';
import img4 from '../images/CloudMigration.svg';

import Accordion from './CapabilitiesFAQ/Accordion';
import { accordionData }  from './CapabilitiesFAQ/AccordionData';

const CapAI = () => {

    return (
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='capAI' >
        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                <img alt="card img" className="rounded-t float-right" src={aichatbot} />
            </div>
            <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                
                <h2 className="text-3xl  text-blue-900 font-bold">Artificial intelligence (AI) Consulting </h2>
                <div>
                    <p className='my-3 text-xl text-gray-600'>
                    AI, which stands for "any technique that enables computer to mimic human intelligence, including machine learning and deep learning," is more than simply a catchphrase used in the industry. 
                    With pre-built, customizable models and APIs that are out-of-the-box, this is becoming more and more popular in corporate use cases for increasing productivity and being quickly adopted by companies to create intelligent, cutting-edge, market-ready, and responsible apps. 
                    </p>
                    <p className='my-3 text-xl text-gray-600'>Azure, Amazon, and Google offer AI services via REST APIs and client library SDKs in widely used programming languages.
                    </p>
                    <p className='my-3 text-xl text-gray-600'>
                    Our technical experts with machine learning experience, cloud architecture experts that holds many years of research & development background draft the action plan for AI enablement in your organization after understanding your specific business need.
                    </p>
                </div>
                
              
            
            </div>
        </div>
        <h4 className="mt-4 mx-12 text-center text-xl lg:text-2xl text-blue-600 font-itallic">
                            "Expand our technological know-how to investigate and contrast AI services offered by top vendors; some examples of popular AI services are:"</h4>
                    {/* AI Service name */}
                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                        </svg>    
                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">AI Bot Service</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Bots deliver an experience that feels less like utilizing a computer and more like dealing with a person—or clever robot. Azure, AWS, and Google all provide AI-powered bot services; let us investigate the best match solution for your specific business use case. </p>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                                    </svg>

                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">AI Document Analysis</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Another excellent AI-powered service for document analysis that reads crucial information with speed and precision. Select from Azure AI Document intelligence, AWS data extraction and analysis service, or Google Document AI. </p>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>

                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">AI Anomaly detection </h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Another excellent AI-powered service for document analysis that reads crucial information with speed and precision. Select from Azure AI Document intelligence, AWS data extraction and analysis service, or Google Document AI.</p>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                                    </svg>
   
                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">AI Translate Service</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Investigate the possibilities of translating text from web sites and documents into hundreds of local languages to serve a wide range of use cases, such as call center translation, multilingual conversational agents, and in-app communication.</p>
                                
                            </div>
                        </div>                 
                    </div>
                </div>
    </div>
    )
}

export default CapAI;