import React from 'react';
import img1 from '../images/consultation.svg';
import img2 from '../images/AI-Chatbot.svg';
import img3 from '../images/AI-VisualData.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
        
        <div className="my-6 py-6"></div>
                 <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-800  font-bold">Capabilities / AI-Cloud</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        {/* <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl text-blue-600 font-itallic">
                            "Enhance your business use cases with AI-Cloud Services."</h2> */}
                    </div>

                    <div className="w-full flex items-center justify-center text-white capdetails">
            <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">                    
                <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="mb-4">
                        <p className='text-2xl md:text-4xl font-normal mb-4'>
                        Together, develop cutting-edge next-generation applications and improve your business use cases with AI-Cloud Services!</p>
                        
                    </div>
            
                </div>
            </div>
        </div>
        </>
    )
}

export default Intro;