import React from 'react';
import img from '../images/Database.svg'; 
import img2 from '../images/app.svg';
import cloudvisualdata from '../images/AI-VisualData.svg';
import img4 from '../images/CloudMigration.svg';

import database from '../images/database.png'; 
import web from '../images/web.png';
import api from '../images/api.png';
import kubernetes from '../images/kubernetes.png';
import Accordion from './CapabilitiesFAQ/Accordion';
import { accordionData }  from './CapabilitiesFAQ/AccordionData';

const CapCloud = () => {

    return (
      <>
      
      <div className="my-6 py-6"></div>
                 <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-800  font-bold">Contact Us</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                     
                    </div>

                    <div className="w-full flex items-center justify-center text-white contactheader">
            <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">                    
                <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="mb-4">
                    <p className='text-2xl md:text-4xl font-bold mb-4'> 
                        Your free technical consultation on Cloud and AI Services is only a mouse click away. </p>
                        <p className="text-lg md:text-2xl">
                        Send us an email, and one of our cloud advocates will contact you to work <span className='font-black'>together!</span></p>
                    </div>
            
                </div>
            </div>
        </div>
      </>
    )
}

export default CapCloud;