import React from 'react';
import { Link } from 'react-router-dom';
import kws from '../images/cloud/azure.png';
import geps from '../images/cloud/gcloud.png';
import protergia from '../images/cloud/aws.png';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}
const Providers = () => {
    return (
        <>
              <div className="my-4 py-4">
                        {/* <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2> */}
                        <h2 className="text-3xl text-center  text-blue-900 font-bold">We work on?</h2>
                        {/* <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div> */}
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl  text-blue-900">We work with some of the top cloud providers while remaining vendor agnostic in order to identify the best solutions for your specific business needs.</h2>
                    </div>
                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3">
                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100 w-1/6">
                            <img src={kws} alt="client" />                           
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={protergia} alt="client" />                            
                        </div> 

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                            <img src={geps} alt="client" />                            
                        </div>

                                           
                    </div>
                </div>
               
            
        </>
    )
}

export default Providers;