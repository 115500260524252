import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import Cta from '../components/Cta';
import CapServicesFAQ from '../components/CapServicesFAQ';
import CapIntro from '../components/CapIntro';
import CapHeader from '../components/CapHeader';
import CapAI from '../components/CapAI';
import CapCloud from '../components/CapCloud';
import OurFocus from '../components/OurFocus';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Providers from '../components/Providers';


// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
const Capabilities = () => {
    return (
        <>
           <div>
                <NavBar />
            </div>
            <CapHeader />
            <CapAI />
            <CapCloud />
            
           <OurFocus />
            <Cta />
           
            <Footer/>
        </>

    )
}

export default Capabilities;

