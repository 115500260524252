import React from 'react';
import img from '../images/Database.svg'; 
import img2 from '../images/app.svg';
import cloudvisualdata from '../images/AI-VisualData.svg';
import img4 from '../images/CloudMigration.svg';

import database from '../images/database.png'; 
import web from '../images/web.png';
import api from '../images/api.png';
import kubernetes from '../images/kubernetes.png';
import Accordion from './CapabilitiesFAQ/Accordion';
import { accordionData }  from './CapabilitiesFAQ/AccordionData';

const CapCloud = () => {

    return (
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='capAI' >
                  <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='capIntro2' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h2 className="text-3xl  text-blue-900 font-bold">Cloud Consulting </h2>
                            <div>
                                <p className='my-3 text-xl text-gray-600'>
                                    We are aware that moving to the cloud can offer businesses several advantages, from improved agility and scalability to cost savings and chances for innovation. The procedure could be intimidating for people who are new to cloud adoption, though. We can help you succeed by navigating the complexities and providing tailored solutions with the help of our cloud computing consulting services at Acklod.
                                 </p>
                                 <p className='my-3 text-xl text-gray-600'>Our cloud advising and consulting team will work directly with you to map out your cloud transformation journey, whether you want entirely cloud-native, hybrid, single, or multi-cloud solutions. </p>
                            </div>
                           
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={cloudvisualdata} />
                        </div>
                        
                    </div>
                </div>
               
                   <h4 className="mt-4 mx-12 text-center text-xl lg:text-2xl text-blue-600 font-itallic">
                            "Application Modernization using Cloud Services, get most out of your <span className='font-black'>cloud investment!</span>"</h4> 
                     
                    <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                     <img alt="card img" className="rounded-t float-right" src={web} />
                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Frontend/Web</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Full-stack development that integrates a web application with cloud native services. Understand static and dynamic resources for cloud-based web applications.</p>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                <img alt="card img" className="rounded-t float-right" src={api} />
                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">API Gateway</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                API Gateway is used to protect APIs since APIs are the conduits through which apps access data, business logic, or functionality from your backend services. Learn about Amazon API Gateway, Azure API Management, and Google API Gateway. </p>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                <img alt="card img" className="rounded-t float-right" src={kubernetes} />

                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Kubernetes </h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">Kubernetes is a container orchestration system that is open source and may be used to automate software deployment, scaling, and administration. Compare Kubernetes Services on main cloud providers Azure AKS vs Amazon Kubernetes vs Google Kubernetes.</p>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="flex justify-center my-4">
                                <img alt="card img" className="rounded-t float-right" src={database} />
   
                                </div> 
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Database</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">A cloud database is a database that often runs on a cloud computing platform and provides access to the database as a service. Relational databases, NoSQL databases, time-series databases, and data warehouses were all discussed. </p>
                                
                            </div>
                        </div>                 
                    </div>
                </div>
    </div>
    )
}

export default CapCloud;