import React from 'react';


const clientImage = {
   // height: '1rem',
    width: '25rem',
    mixBlendMode: 'colorBurn'

}

const Services = () => {

    return (
        <>
         
         <div className="my-4 py-12 bg-gray-100  ">
                  
                  <h2 className="text-3xl text-center  text-blue-900 font-bold">Our Focus</h2>
                 
                  <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl  text-blue-900">
                  "Implementing Artificial Intelligence (AI) and Cloud in various sectors raises problems about governance and security, while as technical specialists we keep ourselves focused on these changes so that we can embrace best practices and continue to give results to our clients."</h2>
                  <h3 className="mt-4 mx-12 text-center text-xl lg:text-2xl  text-blue-900">Thus, by applying the 3-A's, our capabilities grow.</h3>
           
                  <div className="my-2" ></div>
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3">
                                
                                {/** STEPS */} 

                                    <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                        <div className='overflow-hidden flex justify-center text-blue-900 mb-4'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                        </svg>
                                            <h3 className=" testPad text-3xl  text-blue-900 
                                            font-bold"><span className='font-black'>Accelerate </span>Learning</h3>
                                        </div>
                                        {/* <div >
                                            <p className='my-3 text-xl text-gray-600 '>
                                            Cost effective comparision on all major cloud servcice to save your business money.
                                            </p>
                                        </div> */}
                                    </div>

                                    <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                        <div className='overflow-hidden flex justify-center text-blue-900 mb-4'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                                        </svg>
                                            <h3 className="testPad text-3xl  text-blue-900 
                                            font-bold"><span className='font-black'>Affirm </span>Quality</h3>
                                        </div>
                                        {/* <div >
                                            <p className='my-3 text-xl text-gray-600 text-wrap width:24rem'  >
                                            <ul>
                                                <li> Cost effective comparision on all major cloud servcice to save your business money.</li>
                                                <li> Cost effective comparision on all major cloud servcice to save your business money.</li>
                                        
                                            
                                            </ul>
                                            </p>
                                        </div> */}
                                    </div>


                                    <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                        <div className='overflow-hidden flex justify-center text-blue-900 mb-4'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                                    </svg>

                                            <h3  className=" testPad text-3xl  text-blue-900 
                                            font-bold"><span className='font-black' >Appraise </span>Delivery</h3>
                                        </div>
                                        {/* <div >
                                            <p className='my-3 text-xl text-gray-600 '>
                                            Cost effective comparision on all major cloud servcice to save your business money.
                                            </p>
                                        </div> */}
                                    </div>
                    </div>
          
         
          </div>
          
  </>
    )
}

export default Services;