import React from 'react';
import { Link } from 'react-router-dom';
import NotFound from '../images/PageNotFound.jpg';

const PageNotFound = () => {
    return ( 
      <>
      <div className="my-6 py-6"></div>
      <div className="my-4 py-4">
             <h2 className="my-2 text-center text-3xl text-blue-800  font-bold">Page Not Found!</h2>
             
             <div className='flex justify-center'>
                 <div className='w-24 border-b-4 border-blue-900'></div>
             </div>
             {/* <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl text-blue-600 font-itallic">
                 "Enhance your business use cases with AI-Cloud Services."</h2> */}
         </div>

         <div className="w-full flex items-center justify-center text-white">
        <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">                    
          <div className="w-full flex flex-col lg:flex-row lg:justify-around">
                    <div className="lg:w-1/4 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={NotFound} />
                      </div>
      
          </div>
 </div>
</div>
</>
        //  <div>
        //     <img src={NotFound}  />
        //     <p style={{textAlign:"center"}}> Page Not Found!
        //       <Link to="/">Go to Home </Link>
        //     </p>
        //   </div>
          );
        
    }    
export default PageNotFound;