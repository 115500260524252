import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
//import costImg from '../images/icons8-cost-100.png';
const clientImage = {
   // height: '1rem',
    width: '25rem',
    mixBlendMode: 'colorBurn'

}

const Services = () => {

    return (
        <>
         {/* <div id="services" className="bg-gray-100 py-12" > */}
            <div className="my-4 py-12 bg-gray-100  ">
                  {/* <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2> */}
                  <h2 className="text-3xl text-center  text-blue-900 font-bold">How we work?</h2>
                  {/* <div className='flex justify-center'>
                      <div className='w-24 border-b-4 border-blue-900'></div>
                  </div> */}
                  <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl  text-blue-900">
                  "Our goal is to keep things simple and provide comprehenshive documentation that cover solutions, risks, security and cost. We make sure we addressed each and every concerns that you bring to us."</h2>
              <div className="my-2" ></div>
          {/* <div className="p-16" data-aos="fade-in" data-aos-delay="600"> */}
              <div className="grid sm:grid-cols-3 lg:grid-cols-3 ">
              
                           {/** STEPS */} 

                             <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                <div className='overflow-hidden flex justify-center text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                                </svg>
                                                                
                                    <h3 className=" testPad text-3xl  text-blue-900 
                                    font-bold"><span className='font-black'>Requirement</span> Assesment</h3>
                                </div>
                                {/* <div >
                                    <p className='my-3 text-xl text-gray-600 '>
                                    Cost effective comparision on all major cloud servcice to save your business money.
                                    </p>
                                </div> */}
                            </div>

                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 w-1/6">
                                <div className='overflow-hidden flex justify-center text-blue-900 mb-4'>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                    </svg>
                                
                                    <h3 className="testPad text-3xl  text-blue-900 
                                    font-bold"><span className='font-black'>Documentation </span> Action Plan</h3>
                                </div>
                                {/* <div >
                                    <p className='my-3 text-xl text-gray-600 text-wrap width:24rem'  >
                                     <ul>
                                        <li> Cost effective comparision on all major cloud servcice to save your business money.</li>
                                        <li> Cost effective comparision on all major cloud servcice to save your business money.</li>
                                   
                                    
                                    </ul>
                                    </p>
                                </div> */}
                            </div>


                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 w-1/6">
                                <div className='overflow-hidden flex justify-center text-blue-900 mb-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                            </svg>

                                
                                    <h3  className=" testPad text-3xl  text-blue-900 
                                    font-bold"><span className='font-black' >Implementation</span> Support</h3>
                                </div>
                                {/* <div >
                                    <p className='my-3 text-xl text-gray-600 '>
                                    Cost effective comparision on all major cloud servcice to save your business money.
                                    </p>
                                </div> */}
                            </div>

              

                                     
              </div>
          {/* </div> */}
          </div>
          {/* </div> */}
          
  </>
    )
}

export default Services;